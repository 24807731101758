import React, { memo } from 'react';
import Tooltip from '@common/components/tooltip';

type ListPreviewProps = {
  list: string[];
  textFallback: string;
};

const ListPreview = memo(({ list, textFallback }: ListPreviewProps) => {
  if (!list?.length) {
    return <small>{textFallback}</small>;
  }
  const [first, ...rest] = list;

  const content = (
    <span>
      {first + (rest.length ? `, +${rest.length}` : '')}
    </span>
  );

  if (!rest.length) {
    return content;
  }

  return <Tooltip title={list.join(', ')} fullTitle>{ content }</Tooltip>;
});

export default ListPreview;
