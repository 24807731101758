import React, {
  useEffect, useMemo, useState, useCallback, memo
} from 'react';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import * as R from 'ramda';
import moment from 'moment';

import ProfileImage from '@common/components/profile-image';
import ProgressBar from '@common/components/progress-bar';
import FileExportDropdown from '@common/components/dropdown/file-export-dropdown';
import { BASE_URL } from '@common/constants';
import { Select } from '@common/components/form/inputs/select';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import Api from '../../../../common/services/api';
import Overview from '../../../../common/components/overview';
import Table from '../../../../common/components/table';
import Bar from '../../../../common/components/bar';
import Placeholder from '../../../../common/components/placeholder';
import SearchBar from '../../../../common/components/search-bar';
import Statistics from '../../components/statistics';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import { exportFile } from '../onboarding/statistics';

import ListPreview from './list-preview';

const statisticsTitles = {
  starting: 'learning:course_statistics_titles_starting',
  progressing: 'learning:course_statistics_titles_progressing',
  finished: 'learning:course_statistics_titles_finished',
  overdue: 'learning:course_statistics_titles_overdue'
};

const dateFormat = 'DD MMM YYYY';

function CourseStatisticsUserRow({
  item: user, networkId, push, courseHasDeadline
}) {
  // console.log("debug user", user);
  const { t } = useTranslation();
  // $FlowFixMe
  return [
    <ProfileImage size={35} user={user} />,

    <a
      role="button"
      onClick={() => {
        const url = networkId ?
          `/networks/${networkId}/users/${user.user_id}` :
          `/admin/users/${user.user_id}`;
        push(url);
      }}
    >
      {user.full_name}
    </a>,

    <ListPreview list={user.networks} textFallback={t('core:no_communities')} />,

    <ListPreview list={user.functions} textFallback={t('core:no_function_groups')} />,

    user.started_at ?
      moment(user.started_at).format(dateFormat) :
      <small>{t('learning:use_row_not_started')}</small>,

    user.finished_at ?
      moment(user.finished_at).format(dateFormat) :
      <small>{t('learning:use_row_not_finished')}</small>,

    courseHasDeadline ?
      (
        user.deadline ?
          moment(user.deadline).format(dateFormat) :
          <small>{t('learning:use_row_no_deadline')}</small>
      ) :
      null,

    <ProgressBar status={user.progression} showPercentage />
  ].filter((cell) => !!cell);
}

const emptyStats = {
  starting: {},
  progressing: {},
  finished: {},
  overdue: {},
};

function useCourseStatisticsSegments(
  statistics,
  courseHadDeadline
) {
  const stats = statistics || emptyStats;
  const { t } = useTranslation();
  return useMemo(() => {
    return [{
      type: 'starting',
      title: t(statisticsTitles.starting),
      description: t('learning:course_statistics_titles_starting_description'),
      count: stats.starting.count,
      users: stats.starting.users_preview,
    }, {
      type: 'progressing',
      title: t(statisticsTitles.progressing),
      description: t('learning:course_statistics_titles_progressing_description'),
      count: stats.progressing.count,
      users: stats.progressing.users_preview,
    }, {
      type: 'finished',
      title: t(statisticsTitles.finished),
      description: t('learning:course_statistics_titles_finished_description'),
      count: stats.finished.count,
      users: stats.finished.users_preview,
    },
    courseHadDeadline ?
      {
        type: 'overdue',
        title: t(statisticsTitles.overdue),
        description: t('learning:course_statistics_titles_overdue_description'),
        count: stats.overdue.count,
        users: stats.overdue.users_preview,
      } :
      null
    ].filter((value) => !!value);
  }, [courseHadDeadline, stats, t]);
}

function useCourseStatisticsColumns(courseHasDeadline) {
  const { t } = useTranslation();
  return useMemo(() => {
    return [{
      size: 35,
    }, {
      label: t('learning:course_statistics_column_name'),
      className: 'Table__Cell__Title fs-exclude',
    }, {
      label: t('core:communities')
    }, {
      label: t('core:function_groups')
    }, {
      label: t('learning:course_statistics_column_start')
    }, {
      label: t('learning:course_statistics_column_finished')
    },
    courseHasDeadline ?
      {
        label: t('learning:form_academy_deadline_label')
      } :
      null,
    {
      label: t('learning:course_statistics_column_progress'),
      size: 125
    }].filter((value) => !!value);
  }, [courseHasDeadline, t]);
}

export const LearningCourseStatistics = memo(({
  selectOptions,
  selectFilter,
  setSelectFilter,
  filter,
  setFilter,
  setQuery,
  tableConfig,
  history,
  query,
  courseHasDeadline,
  downloadFile,
  statistics,
  networkId
}) => {
  const { t } = useTranslation();

  const segments = useCourseStatisticsSegments(statistics, courseHasDeadline);
  const columns = useCourseStatisticsColumns(courseHasDeadline);

  const downloadCSV = useCallback(() => downloadFile('csv'), [downloadFile]);
  const downloadXLSX = useCallback(() => downloadFile('xlsx'), [downloadFile]);

  return (
    <>
      <Bar>
        <h2><Trans i18nKey="learning:course_statistics_title" /></h2>

        <Select
          valueKey="id"
          labelKey="name"
          options={selectOptions}
          value={selectFilter?.id || false}
          onChange={(value) => setSelectFilter(value.id ? value : null)}
          clearable={false}
        />
      </Bar>

      <Overview>
        <Overview.Content>
          <Statistics
            onFilter={setFilter}
            statistics={segments}
          />
        </Overview.Content>
      </Overview>

      <Overview>
        <Overview.Content>
          <div className="statsContentTop">
            <SearchBar
              filters={filter ? [t(statisticsTitles[filter])] : undefined}
              removeFilter={() => setFilter(null)}
              onSearch={setQuery}
              placeholder={t('learning:course_statistics_search_bar_placeholder')}
            />
            <FileExportDropdown
              label={t('core:export')}
              downloadCSV={downloadCSV}
              downloadXLSX={downloadXLSX}
            />
          </div>
          <Table
            columns={columns}
            data={tableConfig}
            renderRow={CourseStatisticsUserRow}
            rowProps={{
              push: history.push,
              courseHasDeadline,
              networkId
            }}
            placeholder={(
              <Placeholder
                title={
                  filter ?
                    (
                      query ?
                        t('learning:course_statistics_placeholder_with_filter_and_query', {
                          title: t(statisticsTitles[filter]),
                          query
                        }) :
                        t('learning:course_statistics_placeholder_with_filter', {
                          title: t(statisticsTitles[filter])
                        })
                    ) :
                    query ?
                      t('learning:course_statistics_placeholder_with_query', { query }) :
                      t('learning:course_statistics_placeholder')
                }
              />
            )}
          />
        </Overview.Content>
      </Overview>
    </>
  );
});

export function useCourseStatisticsExporter(
  networkId,
  organisationId,
  courseId,
  filter
) {
  const { t } = useTranslation();
  return useCallback((format) => {
    const folder = networkId ?
      `networks/${networkId}` :
      `organisations/${organisationId}`;
    const baseUrl = `${BASE_URL}/v2/${folder}/courses/${courseId}` +
      '/statistics/userprogress/export';
    const label = t('learning:course_statistics_title');
    const fileNamePrefix = label.split(' ').join('-').toLowerCase();
    return exportFile(baseUrl, filter, fileNamePrefix, format);
  }, [networkId, filter, organisationId, courseId, t]);
}


const LearningCourseStatisticsContainer = ({
  history,
  match,
  organisationId,
  statistics,
  network,
  networks,
  course,
  fetchCourseStatistics,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(null);
  const [selectFilter, setSelectFilter] = useState(network);
  const [query, setQuery] = useState();

  // console.log("debug filter", filter);

  useEffect(() => {
    fetchCourseStatistics(courseId, selectFilter?.id);
  }, [courseId, selectFilter?.id]);

  // console.log("debug LearningCourseStatisticsContainer network", network);

  const selectOptions = useMemo(() => {
    return [
      {
        id: false,
        name: t('learning:onboarding_statistics_whole_organisation')
      },
      ...networks,
    ];
  }, [networks, t]);

  const { courseId } = match.params;

  const tableConfig = useMemo(() => {
    return {
      useCursor: true,
      onFetch: (nextCursor, filters, limit = 10) => {
        const queryString = Api.utils.toQuery({
          cursor: nextCursor,
          limit,
          ...R.omit(['networkId'], filters),
        });

        const urlBase = selectFilter
          ? `/v2/networks/${selectFilter.id}`
          : `/v2/organisations/${organisationId}`;

        return Api.get(
          `${urlBase}/courses/${courseId}/statistics/userprogress?${queryString}`
        );
      },
      filter: {
        networkId: selectFilter?.id,
        q: query,
        filter,
      },
    };
  }, [selectFilter, filter, query, courseId, organisationId]);

  const downloadFile = useCourseStatisticsExporter(
    selectFilter?.id,
    organisationId,
    courseId,
    filter
  );

  return (
    <LearningCourseStatistics
      history={history}
      statistics={statistics}
      courseHasDeadline={!!(course?.settings?.deadline || course?.settings?.deadline_after_days_invited)}
      downloadFile={downloadFile}
      tableConfig={tableConfig}
      selectOptions={selectOptions}
      setSelectFilter={setSelectFilter}
      filter={filter}
      selectFilter={selectFilter}
      setFilter={setFilter}
      setQuery={setQuery}
      query={query}
    />
  );
};

const mapStateToProps = (state, props) => ({
  organisationId: state.organisation.selected.id,
  networks: organisationSelector.networks(state),
  course: state.learning.courses.items[props.match.params.courseId],
  statistics: state.learning.statistics.courses[props.match.params.courseId]
});

const mapDispatchToProps = {
  fetchCourseStatistics: require('../../actions/fetch-course-statistics').default,
};

export default connect(mapStateToProps, mapDispatchToProps)(pageWrapper(EEventNames.VISITED_COURSE_STATISTICS_PAGE)(LearningCourseStatisticsContainer));
