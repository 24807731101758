import Api from '../../../common/services/api';
import { LEARNING_RECEIVE_ONBOARDING_STATISTICS } from './index';

export default (networkId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const urlBase = networkId
    ? `/v2/networks/${networkId}`
    : `/v2/organisations/${selected.id}`;

  const statistics = await Api.get(`${urlBase}/onboarding/statistics`);

  return dispatch({
    type: LEARNING_RECEIVE_ONBOARDING_STATISTICS,
    statistics,
  });
};
