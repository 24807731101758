import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ProfileImage from '@common/components/profile-image';
import ListPreview from '@modules/learning/containers/course/list-preview';
import ProgressBar from '../../../../common/components/progress-bar';

const dateFormat = 'DD MMM YYYY';

const UserRow = ({ item: user, network, push }) => {
  const { t } = useTranslation();

  return [
    <ProfileImage size={35} user={user} />,
    <a onClick={() => push(network ? `/networks/${network.id}/users/${user.user_id}` : `/admin/users/${user.user_id}`)}>{user.full_name}</a>,
    user.invited_at !== undefined && (user.invited_at ? moment(user.invited_at).format(dateFormat) : <small>{t('learning:use_row_not_invited')}</small>),
    <ListPreview list={user.networks} textFallback={t('core:no_communities')} />,
    <ListPreview list={user.functions} textFallback={t('core:no_function_groups')} />,
    user.started_at ? moment(user.started_at).format(dateFormat) : <small>{t('learning:use_row_not_started')}</small>,
    user.finished_at ? moment(user.finished_at).format(dateFormat) : <small>{t('learning:use_row_not_finished')}</small>,
    user.finished_at !== undefined && (user.deadline ? moment(user.deadline).format(dateFormat) : <small>{t('learning:use_row_no_deadline')}</small>),
    <ProgressBar status={user.progression} showPercentage />,
  ].filter((row) => !!row);
};

export default UserRow;
