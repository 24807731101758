import Api from '../../../common/services/api';
import { LEARNING_RECEIVE_COURSE_STATISTICS } from './index';

export default (courseId, networkId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const urlBase = networkId
    ? `/v2/networks/${networkId}`
    : `/v2/organisations/${selected.id}`;

  const statistics = await Api.get(`${urlBase}/courses/${courseId}/statistics`);

  return dispatch({
    type: LEARNING_RECEIVE_COURSE_STATISTICS,
    courseId,
    statistics,
  });
};
