import React from 'react';
import { Trans } from 'react-i18next';
import ProfileImage from '@common/components/profile-image';
import Overview from '../../../../common/components/overview';

const { Row } = Overview.Content;

const LearningStatistics = ({ statistics, onFilter }) => (
  <Row>
    {statistics.map((category, index) => (
      <React.Fragment key={category.type}>
        <Row.Card
          title={category.title}
          description={(
            <>
              <div>{category.description}</div>
              {category.users && (
                <div className="CategoryStatistics__Card__Users">
                  {category.users.map((user) => <ProfileImage key={user.id} size={30} user={user} />)}
                </div>
              )}
            </>
          )}
          count={category.count || 0}
          action={category.type && onFilter && (
            <a onClick={() => onFilter(category.type)}>
              <small><Trans i18nKey="learning:statistics_view_all" /></small>
            </a>
          )}
        />
        {index < statistics.length - 1 && <Row.ArrowDivider />}
      </React.Fragment>
    ))}
  </Row>
);

export default LearningStatistics;
