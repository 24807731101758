import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Trans, useTranslation } from 'react-i18next';

import FileExportDropdown from '@common/components/dropdown/file-export-dropdown';
import { downloadFile } from '@common/utils/file';
import { getApiHeaders } from '@common/services/api/utils';
import { BASE_URL } from '@common/constants';
import { Select } from '@common/components/form/inputs/select';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import Api from '../../../../common/services/api';
import Overview from '../../../../common/components/overview';
import Table from '../../../../common/components/table';
import Bar from '../../../../common/components/bar';
import Placeholder from '../../../../common/components/placeholder';
import SearchBar from '../../../../common/components/search-bar';
import UserRow from '../../components/user-row';
import Statistics from '../../components/statistics';
import * as organisationSelector from '../../../organisation/selectors/organisation';

const useColumns = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return [{
      size: 35,
    }, {
      label: t('learning:onboarding_statistics_column_label_name'),
      className: 'Table__Cell__Title fs-exclude',
    }, {
      label: t('learning:onboarding_statistics_column_label_assigned')
    }, {
      label: t('core:communities')
    }, {
      label: t('core:function_groups')
    }, {
      label: t('learning:onboarding_statistics_column_label_start')
    }, {
      label: t('learning:onboarding_statistics_column_label_finished')
    }, {
      label: t('learning:onboarding_statistics_column_label_deadline')
    }, {
      label: t('learning:onboarding_statistics_column_label_progression'),
      size: 125
    }];
  }, [t]);
};

const useStatisticsTitles = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return {
      starting: t('learning:onboarding_statistics_titles_starting'),
      progressing: t('learning:onboarding_statistics_titles_progressing'),
      finished: t('learning:onboarding_statistics_titles_finished'),
      overdue: t('learning:onboarding_statistics_titles_overdue')
    };
  }, [t]);
};

const LearningOnboardingStatisticsContainer = ({
  history,
  organisationId,
  statistics,
  network,
  networks,
  fetchStatistics,
}) => {
  const { t } = useTranslation();

  const [filter, setFilter] = React.useState(null);
  const [networkFilter, setNetworkFilter] = React.useState(network);
  const [query, setQuery] = React.useState('');

  const networkId = networkFilter?.id;
  useEffect(() => fetchStatistics(networkId), [networkId]);

  const { downloadCSV, downloadXLSX } = useExportOnboardingStatistics(
    filter, networkId, organisationId
  );

  const statisticsTitles = useStatisticsTitles();

  return (
    <>
      <Bar>
        <h2><Trans i18nKey="learning:onboarding_statistics" /></h2>

        {!network && (
          <Select
            valueKey="id"
            labelKey="name"
            options={[
              { id: false, name: t('learning:onboarding_statistics_whole_organisation') },
              ...networks,
            ]}
            value={networkFilter?.id || false}
            onChange={(value) => setNetworkFilter(value.id ? value : null)}
            clearable={false}
          />
        )}
      </Bar>

      <Overview>
        <Overview.Content>
          <Statistics
            onFilter={setFilter}
            statistics={[{
              type: 'starting',
              title: statisticsTitles.starting,
              description: t('learning:onboarding_statistics_titles_starting_description'),
              count: statistics.starting.count,
              users: statistics.starting.users_preview,
            }, {
              type: 'progressing',
              title: statisticsTitles.progressing,
              description: t('learning:onboarding_statistics_titles_progressing_description'),
              count: statistics.progressing.count,
              users: statistics.progressing.users_preview,
            }, {
              type: 'finished',
              title: statisticsTitles.finished,
              description: t('learning:onboarding_statistics_titles_finished_description'),
              count: statistics.finished.count,
              users: statistics.finished.users_preview,
            }, {
              type: 'overdue',
              title: statisticsTitles.overdue,
              description: t('learning:onboarding_statistics_titles_overdue_description'),
              count: statistics.overdue.count,
              users: statistics.overdue.users_preview,
            }]}
          />
        </Overview.Content>
      </Overview>

      <Overview>
        <Overview.Content>
          <div className="statsContentTop">
            <SearchBar
              filters={filter ? [statisticsTitles[filter]] : undefined}
              removeFilter={() => setFilter(null)}
              onSearch={setQuery}
              placeholder={t('learning:onboarding_statistics_search_bar_placeholder')}
            />
            <FileExportDropdown
              label={t('core:export')}
              downloadCSV={downloadCSV}
              downloadXLSX={downloadXLSX}
            />
          </div>
          <Table
            columns={useColumns()}
            data={{
              useCursor: true,
              onFetch: (nextCursor, filters, limit = 10) => {
                const queryString = Api.utils.toQuery({ cursor: nextCursor, limit, ...R.omit(['networkId'], filters) });

                const urlBase = networkFilter
                  ? `/v2/networks/${networkFilter.id}`
                  : `/v2/organisations/${organisationId}`;

                return Api.get(`${urlBase}/onboarding/statistics/userprogress?${queryString}`);
              },
              filter: {
                networkId: networkFilter?.id,
                q: query,
                filter,
              },
            }}
            renderRow={UserRow}
            rowProps={{
              push: history.push,
              network,
            }}
            placeholder={(
              <Placeholder
                title={filter
                  ? query
                    ? t('learning:onboarding_statistics_placeholder_with_filter_and_query', { title: statisticsTitles[filter], query })
                    : t('learning:onboarding_statistics_placeholder_with_filter', { title: statisticsTitles[filter] })
                  : query
                    ? t('learning:onboarding_statistics_placeholder_with_query', { query })
                    : networkFilter
                      ? t('learning:onboarding_statistics_placeholder_network', { networkName: networkFilter.name })
                      : t('learning:onboarding_statistics_placeholder')}
              />
            )}
          />
        </Overview.Content>
      </Overview>
    </>
  );
};

function useExportOnboardingStatistics(filter, networkId, organisationId) {
  const folder = networkId ?
    `networks/${networkId}` :
    `organisations/${organisationId}`;
  const baseUrl = `${BASE_URL}/v2/${folder}/onboarding/statistics/userprogress/export`;

  const { t } = useTranslation();
  const stats = t('learning:onboarding_statistics');
  const fileNamePrefix = stats.split(' ').join('-').toLocaleLowerCase();

  return useStatisticsExporters(baseUrl, filter, fileNamePrefix);
}

export async function exportFile(baseUrl, filter, fileNamePrefix, format) {
  let url = `${baseUrl}?format=${format}`;
  if (filter) {
    url += `&filter=${filter}`;
  }

  let fileName = fileNamePrefix;
  if (filter) {
    fileName += `-${filter}`;
  }
  fileName += `.${format}`;

  const options = {
    method: 'GET',
    headers: {
      ...(await getApiHeaders()),
      Accept: `text/${format}`
    },
    credentials: 'include',
    cache: 'no-cache'
  };

  return downloadFile(url, fileName, options);
}

export function useStatisticsExporters(baseUrl, filter, fileNamePrefix) {
  const download = useCallback((format) => {
    return exportFile(baseUrl, filter, fileNamePrefix, format);
  }, [filter, baseUrl, fileNamePrefix]);

  const downloadCSV = useCallback(() => download('csv'), [download]);
  const downloadXLSX = useCallback(() => download('xlsx'), [download]);
  return { downloadCSV, downloadXLSX };
}

const mapStateToProps = (state) => ({
  organisationId: state.organisation.selected.id,
  statistics: state.learning.statistics.onboarding,
  networks: organisationSelector.networks(state),
});

const mapDispatchToProps = {
  fetchStatistics: require('../../actions/fetch-onboarding-statistics').default,
};

export default connect(mapStateToProps, mapDispatchToProps)(pageWrapper(EEventNames.VISITED_ONBOARDING_STATISTICS_PAGE)(LearningOnboardingStatisticsContainer));
